import React from 'react';
import { graphql } from 'gatsby'
import Layout from 'components/Layout';
import Testimonials from "../components/Testimonials";

export default class TestimonialsPage extends React.Component {
  render() {
    const { aldenImg } = this.props.data;
    return <Layout
      location={this.props.location}
      title={"Stonybrook Strings | Testimonials"}
      description={"See what parents have to say about Stonybrook String and Margaret Cerjan, its founder and musical director"}
    >
      <Testimonials aldenImg={aldenImg.childImageSharp.gatsbyImageData}/>
    </Layout>;
  }
}

export const query = graphql`
  query TestimonialsQuery {
    aldenImg: file(relativePath: { eq: "IMG_5007.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout:CONSTRAINED, width: 1000)
      }
    }
  }
`;