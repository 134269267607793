import React from 'react';
import * as styles from "./styles.module.scss";
import star from 'assets/svg/star.svg';
import Svg from 'react-inlinesvg';
import { GatsbyImage } from "gatsby-plugin-image";

function getStars(num) {
  let result = [];

  for (let i = 1; i <= num; i++) {
    result.push(<Svg key={`star-${i}`} src={star}/>);
  }

  return result;
}

function TestimonialWrapper({ testimonial, children }) {
  if (testimonial.url) {
    return <a className={styles.root} href={testimonial.url} target={"_blank"} rel="noreferrer">
      {children}
    </a>
  }

  return <div className={styles.root}>
    {children}
  </div>
}

export default function Testimonial({ testimonial, aldenImg }) {
  const { id, stars, body, author } = testimonial;

  return <TestimonialWrapper testimonial={testimonial}>
    {(stars || author) && <div className={styles.header}>
      {stars && <span className={styles.stars}>{getStars(stars)}</span>}
      {author && <span className={styles.author}>{author}</span>}
    </div>}
    <div dangerouslySetInnerHTML={{__html: body}} />
    {id === 'alden' && <div className={styles.imgWrapper}><GatsbyImage className={styles.img} image={aldenImg} alt={"Maggie with a star student"}/></div>}
  </TestimonialWrapper>
}