import React from 'react';
import { TESTIMONIALS } from "data/testimonials";
import * as styles from "./styles.module.scss";
import Testimonial from "./Testimonial";

export default function Testimonials({ aldenImg }) {
  return <div className={styles.root}>
    <h1>Testimonials</h1>
    {TESTIMONIALS.map((t, i) => <Testimonial testimonial={t} key={`testimonial-${i}`} aldenImg={aldenImg}/>)}
  </div>
}