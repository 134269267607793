export const TESTIMONIALS = [
  {
    id: 'alden',
    author: 'Amelia O.',
    body: `<p>Maggie Cerjan is a gifted teacher. She connects with the child where they are, makes the lessons fun, 
        and paces every suggestion and new skill to the child's ability and learning style. She is gentle and
        encouraging, and teaches to a high standard and expects her students to work hard. My beginning student 
        loves his lessons and loves Maggie; I'm so glad we found Stonybrook Springs!</p>`,
    stars: 5,
    url: 'https://www.thumbtack.com/ma/boston/violin-lessons/stonybrook-strings/service/325434956319252490'
  },
  {
    author: 'Janice & Michael C.',
    stars: 5,
    body: `<p>Our daughter, has been taking private violin lessons with Maggie for five years. It has been fabulous to see
        our daughter’s progression, in skill, musicality and confidence. Maggie has always encouraged her to reach
        higher and further - to truly challenge herself. She has encouraged participation in ensembles,
        training orchestra (NEC) and summer camp/clinics, as well as solo performances. Through active engagement,
        our daughter’s appreciation for the violin, the music, and taking on the next challenge has grown. A somewhat
        quiet and shy child, she has become more confident and rarely shies away from an audition or opportunity to
        perform. Friends and adults often comment on her ability and confidence. Maggie has been a significant force
        behind our daughter’s development.</p>
        
        <p>While getting a child to practice is always a challenge, increasingly our daughter has been more willing as the
        music and skills required have become more challenging. She is more willing to put in the time, especially when
        Maggie recognizes the progress she is making. Our daughter’s practice is part of the morning routine, and
        having a successful practice starts the day on a positive note. She feels great pride when she can check the
        assignments off and share them with Maggie at her weekly lesson.</p>
        
        <p>Maggie is a skilled, patient and yet demanding instructor. She focuses on getting the mechanics just right, 
        establishing a strong foundation of skills, and teaching pieces that test and build capability. She knows when
        and how to push our daughter further. Our daughter has a very positive relationship with Maggie and always
        strives to please her. She feels very comfortable asking for help when she needs it as she always wants to
        get better. As a family we feel blessed to have Maggie teaching our daughter. We would highly recommend Maggie
        to any family who is seeking the very best in a violin teacher.</p>`
  },
  {
    body: `<p>We are thrilled with the quality of instruction, dedication, and professionalism from Maggie at Stonybrook
        Strings. For both our children, Maggie has formed a strong bond as a teacher, mentor, and role model. She is
        extremely devoted to her students and their growth, looking for opportunities to enhance their musical education
        while understanding each student as an individual learner. Maggie continuously surpasses expectations!</p>`,
    stars: 5,
    url: 'https://www.thumbtack.com/ma/boston/violin-lessons/stonybrook-strings/service/325434956319252490'
  },
  {
    author: 'Craig N',
    stars: 5,
    body: `<p>Great option for kids learning violin. Instructor is amazing at engaging with children and modifies the
        class based on each child's needs. Our child has a short attention span and Maggie somehow gets her to pay
        attention and progress pretty quickly. The students also get to perform at different venues and give back to
        the community around them.</p>`,
    url: 'https://www.google.com/search?client=firefox-b-1-d&q=stonybrook+strings#lrd=0x89e379674816556b:0xbdc6b872bb4d1603,1,,,'
  }
];